#card{
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: column;
    margin-left: 20px;
}

#cardimg{
    height: 380px;
    width: 230px;

    border-radius: 10px;
}

#cardimg img{
    height: 100%;
    width: 100%;
    border-radius: 10px;
}



@media only screen and (max-width:600px){
    #card{
        display: flex;
        align-items: start;
        justify-content: start;
        flex-direction: column;
        margin-left: 20px;
        margin-top: 20px;
    }
    
    #cardimg{
        height: 250px;
        width: 150px;
    
        border-radius: 10px;
    }
}