#succ{
    display: flex;
    min-height: 900px;
    background-color: rgb(225, 222, 222);
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#brcode{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  
}

@media only screen and (max-width: 600px){
    #brcode{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

    }
}
